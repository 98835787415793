var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticStyle:{"padding":"0px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%","height":"60px"}},[_c('div',{staticClass:"Enter-top"},[_c('div',[_c('div',[_c('a-breadcrumb',{staticStyle:{"margin":"18px 0"}},[_c('a-breadcrumb-item',[_vm._v("小程序")]),_c('a-breadcrumb-item',[_vm._v("参数配置")])],1)],1)])])]),_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{style:({
        background: '#fff',
        padding: '24px',
        margin: 0,
        overflow: _vm.auto,
      })},[_c('div',{staticStyle:{"padding-top":"30px"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"rules":_vm.rules,"model":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{ref:"value",attrs:{"prop":"value","label":"品牌库每日查询次数"}},[_c('a-input',{on:{"blur":() => {
                  _vm.$refs.name.onFieldBlur()
                }},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 每个账号每天可查询品牌库的次数 ")]),_c('a-icon',{staticClass:"icon-url",attrs:{"type":"exclamation-circle"}})],2)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 8, offset: 8 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 提交 ")])],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }